import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { Button, Box, Menu, MenuItem, Typography, Link as MaterialLink } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '../Link';
import NextLink from 'next/link';
import SignupButton from '../SignupButton';
import LoginButton from '../LoginButton';
import UserMenu from '../UserMenu';
import { USFlag, SpainFlag, BrazilFlag, Woxo } from '../Icon';
import { useAuthentication } from '../../hooks/useAuthentication';
import translate from '../../data/translations';
import UserNotification from '../UserNotification';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import ExpandedMenu from '../ExpandedMenu';
import HelpMenu from '../HelpMenu';
import { useRouter } from 'next/router';
import { useBulkVideoStore } from '../BulkCreator/stores/store';
import TutorialVideos from '../TutorialVideos/TutorialVideos';
import { useIdeaToVideoStore } from '../IdeaToVideo/stores/store';
import { UpgradeButton } from '../UpgradeButton';
import Banner from '../Banner';
import { BannerContext } from '~/components/Banner';

const drawerWidth = '100%';

const useStyles = makeStyles((theme) => ({
  toolbar: ({ pathname }) => ({
    height: 96,
    display: 'flex',
    zIndex: 10,
    justifyContent: 'space-between',
    '--padding': '15px',
    padding: '0px var(--padding)',
    '&.height-69': {
      height: 69
    },
    '@media only screen and (min-width: 965px)': {
      '--padding': '30px'
    },
    '@media only screen and (min-width: 1280px)': {
      '--padding': '56px'
    },
    ...(pathname !== '/browse/[id]'
      ? { backgroundColor: 'white' }
      : { background: 'linear-gradient(90deg, rgba(2,129,245,1) 0%, rgba(147,39,143,1) 100%)' })
  }),
  appBar: ({ pathname }) => ({
    boxShadow: 'none',
    transition: 'transform 0.25s ease',
    '@media only screen and (min-width: 768px)': {
      boxShadow: '0px 3px 6px #00000008'
    },
    '&.open': {
      transform: 'translateY(0px)'
    },
    '&.closed': {
      transform: 'translateY(-96px)'
    },
    ...(pathname !== '/browse/[id]' && { backgroundColor: 'white' })
  }),
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  avatar: {
    width: 'auto',
    borderRadius: '0px !important',
    height: 30,
    marginRight: '20px'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1,
    '@media only screen and (max-width: 768px)': {
      display: 'none'
    }
  },
  menuButton: {
    padding: 6,
    borderRadius: '50%',
    background: '#fff',
    color: '#1789fc',

    '& svg': {
      width: 22,
      height: 22
    },

    '&:hover': {
      backgroundColor: '#F6FBFF',
      color: '#1789fc'
    }
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  homeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginRight: '10%',
    '@media only screen and (max-width: 1280px)': {
      marginRight: 24
    },
    '@media only screen and (max-width: 1024px)': {
      marginRight: 24
    },
    '@media only screen and (max-width: 320px)': {
      marginLeft: 0
    }
  },
  menuLink: {
    fontSize: 14,
    position: 'relative',
    letterSpacing: '1.4px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '19px',
    color: '#414042',
    textDecoration: 'none',
    // textTransform: 'uppercase',
    marginRight: '5%',
    transition: 'all 0.2s ease-in-out',
    '@media only screen and (max-width: 1024px)': {
      marginRight: '3%'
    },
    '&:before': {
      content: "''",
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: '1px',
      margin: '5px 0 0',
      opacity: 0,
      backgroundColor: '#414042',
      transition: 'all 0.2s ease-in-out',
      transitionDuration: '0.35s',
      left: '50%'
    },
    '&:after': {
      content: "''",
      position: 'absolute',
      bottom: '-10px',
      width: 0,
      height: '1px',
      margin: '5px 0 0',
      opacity: 0,
      backgroundColor: '#414042',
      transition: 'all 0.2s ease-in-out',
      transitionDuration: '0.35s',
      right: '50%'
    },
    '&:hover:before': {
      left: '50%',
      width: '50%',
      opacity: 1
    },
    '&:hover:after': {
      right: '50%',
      width: '50%',
      opacity: 1
    }
  },
  menuLinkMobile: {
    fontSize: 14,
    letterSpacing: '1.4px',
    lineHeight: '19px',
    color: '#414042',
    textDecoration: 'none',
    // textTransform: 'uppercase',
    marginBottom: '30px'
  },
  menuContainer: {
    width: '100%',
    '@media only screen and (max-width: 960px)': {
      width: 0
    }
  },
  menuMobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 32
  },
  backIcon: {
    padding: 6,
    borderRadius: '50%',
    background: '#fff',
    color: '#1789fc',

    '& svg': {
      width: 22,
      height: 22
    },

    '&:hover': {
      backgroundColor: '#F6FBFF',
      color: '#1789fc'
    }
  },
  menuMobileContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuDesktopContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  languageText: {
    fontSize: 14,
    letterSpacing: '1.4px',
    lineHeight: '17px',
    color: '#414042',
    fontWeight: 500,
    marginLeft: 16,
    textDecoration: 'none'
  },
  browseText: {
    fontSize: 14,
    letterSpacing: '1.4px',
    lineHeight: '19px',
    color: '#414042',
    fontWeight: 300,
    marginLeft: 16,
    textDecoration: 'none'
  },
  languageTextLink: {
    textDecoration: 'none',
    display: 'flex'
  },
  browseTextLink: {
    textDecoration: 'none',
    display: 'flex'
  },
  languageSelector: {
    marginLeft: 20,
    '@media only screen and (max-width: 1280px)': {
      marginLeft: 30
    },
    '@media only screen and (max-width: 1024px)': {
      marginLeft: 10
    }
  },
  activeLink: {
    color: '#0086FF',
    '&:before': {
      backgroundColor: '#0086FF'
    },
    '&:after': {
      backgroundColor: '#0086FF'
    }
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 20px',
    alignItems: 'center'
  },
  notificationMobile: {
    flex: 1,
    textAlign: 'right',
    marginRight: 24,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuHelp: {
    marginLeft: 15
  },
  upgradeButton: {
    marginRight: '12px',
    // borderRadius: 8,
    '& span span': {
      paddingRight: '12px!important'
    }
  }
}));

const MainAppBar = ({
  position,
  lang,
  children,
  className,
  showSignUp,
  showLogin,
  loginRedirect,
  signUpLabel,
  showUserMenu,
  endContent,
  showLinks,
  audience
}) => {
  const {
    user,
    showLoginWithRedirect,
    isAuthenticated,
    showLogin: onLogin,
    showSignUp: onSignUp
  } = useAuthentication();
  const router = useRouter();
  const classes = useStyles({ pathname: router.pathname });

  const [open, setOpen] = React.useState(false);
  const { isActive } = React.useContext(BannerContext);

  const bulkState = useBulkVideoStore((state) => state);
  const ideaToVideoState = useIdeaToVideoStore((state) => state);
  const prevScrollPosRef = React.useRef(0);
  const [visible, setVisible] = React.useState(true);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPosRef.current > currentScrollPos;
      setVisible(currentScrollPos > prevScrollPosRef.current ? false : true);
      prevScrollPosRef.current = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogin = () => {
    if (router.asPath.includes('bulk-creator')) {
      // TODO: Make a better handler of flags
      localStorage.setItem('bulkCreatorState', JSON.stringify(bulkState));
    }
    if (router.asPath.includes('idea-to-video')) {
      // TODO: Make a better handler of flags
      localStorage.setItem('ideaToVideoState', JSON.stringify(ideaToVideoState));
    }
    localStorage.removeItem('planPaymentItem');
    loginRedirect ? showLoginWithRedirect(router.asPath === '/' ? null : router.asPath) : onLogin();
  };

  const handleSignUp = () => {
    if (router.asPath.includes('bulk-creator')) {
      // TODO: Make a better handler of flags
      localStorage.setItem('bulkCreatorState', JSON.stringify(bulkState));
    }
    if (router.asPath.includes('idea-to-video')) {
      // TODO: Make a better handler of flags
      localStorage.setItem('ideaToVideoState', JSON.stringify(ideaToVideoState));
    }
    localStorage.removeItem('planPaymentItem');
    loginRedirect
      ? showLoginWithRedirect(router.asPath === '/' ? null : router.asPath, 'signup', audience)
      : onSignUp();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const LanguageButton = ({ language }) => {
    let componentToRender = (
      <>
        <USFlag />
        <Typography className={clsx(classes.languageText, 'language')}>ENG</Typography>
      </>
    );

    if (language === 'pt') {
      componentToRender = (
        <>
          <BrazilFlag />
          <Typography className={clsx(classes.languageText, 'language')}>PT</Typography>
        </>
      );
    } else if (language === 'es') {
      componentToRender = (
        <>
          <SpainFlag />
          <Typography className={clsx(classes.languageText, 'language')}>ES</Typography>
        </>
      );
    }

    return componentToRender;
  };

  return (
    <AppBar
      position={position}
      className={clsx(
        classes.appBar,
        className,
        visible && router.pathname === '/'
          ? 'open'
          : !visible && router.pathname === '/'
          ? 'closed'
          : ''
      )}
      color="inherit"
    >
      {/* <Banner /> */}
      <Toolbar className={clsx(classes.toolbar, router.pathname === '/' ? 'height-69' : '')}>
        {children === null && (
          <>
            <div className={classes.homeContainer}>
              <Link href={`${isAuthenticated ? '/dashboard' : '/'}`}>
                <Woxo x="0px" y="0px" viewBox="0 0 185.3 59.8" />
              </Link>
            </div>
            {showLinks && (
              <div className={classes.menuContainer}>
                <Box className={classes.sectionDesktop}>
                  <ExpandedMenu type={'videoAI'} />
                  <ExpandedMenu type={'widget'} />
                  {/* <ExpandedMenu type={'video'} /> */}

                  <Link
                    href="/plans"
                    activeClassName={classes.activeLink}
                    className={classes.menuLink}
                  >
                    {translate('plans', lang, 'Plans')}
                  </Link>
                  {/* <Link
                    href="/about"
                    activeClassName={classes.activeLink}
                    className={classes.menuLink}
                  >
                    {translate('aboutUs', lang, 'About us')}
                  </Link> */}
                  {/* <TutorialVideos /> */}
                  {/* <a
                    href="https://woxo.tech/blog/"
                    activeClassName={classes.activeLink}
                    className={classes.menuLink}
                  >
                    {translate('blog', lang, 'Blog')}
                  </a> */}
                  {/* <Link
                    href="https://support.woxo.tech/en/"
                    className={classes.menuLink}
                    target="_blank"
                  >
                    {translate('supportCenter', lang, 'Support center')}
                  </Link> */}
                </Box>
              </div>
            )}
          </>
        )}
        {children}
        {showLinks && (
          <Box display="flex" alignItems="baseline" flex="0 0 auto">
            <div className={classes.sectionDesktop}>
              {!user && showLogin && (
                <LoginButton onClick={handleLogin} lang={lang} label="Log in" />
              )}
              {!user && showSignUp && (
                <SignupButton onClick={handleSignUp} lang={lang} label={signUpLabel} />
              )}

              {!!user && router.route === '/dashboard' && (
                <UpgradeButton className={classes.upgradeButton} category="video" />
              )}
              {!!user && showUserMenu && <UserMenu user={user} />}
              {/* {!!user && showNotificationPanel && <UserNotification />} */}

              {/* <Link
                href="https://support.woxo.tech/en/"
                target="_blank"
                className={clsx(classes.menuLink, classes.menuHelp, 'help-btn')}>
                <HelpOutlineIcon />
              </Link> */}
              {/* <HelpMenu /> */}

              {/* {showLanguageMenu && (
                <div>
                  <Button
                    aria-controls="simple-menu1"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className={classes.languageSelector}
                  >
                    <LanguageButton language={lang} />
                  </Button>
                  <Menu
                    id="simple-menu1"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      data-name="navbar-lang-en"
                      data-type="btn-click"
                      onClick={handleClose}
                    >
                      <Link href="/" className={classes.languageTextLink}>
                        <USFlag />
                        <Typography className={classes.languageText}>ENG</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      data-name="navbar-lang-es"
                      data-type="btn-click"
                      onClick={handleClose}
                    >
                      <Link href="/es" className={classes.languageTextLink}>
                        <SpainFlag />
                        <Typography className={classes.languageText}>ES</Typography>
                      </Link>
                    </MenuItem>
                    <MenuItem
                      data-name="navbar-lang-pt"
                      data-type="btn-click"
                      onClick={handleClose}
                    >
                      <Link href="/pt" className={classes.languageTextLink}>
                        <BrazilFlag />
                        <Typography className={classes.languageText}>PT-Br</Typography>
                      </Link>
                    </MenuItem>
                  </Menu>
                </div>
              )} */}
            </div>
            {/* <Box className={clsx(classes.notificationMobile, 'notification-mobile')}>
              {!!user && showNotificationPanel && <UserNotification />}
            </Box> */}
            <div className={clsx(classes.sectionMobile, 'sectionMobile')}>
              <Box className={classes.menuMobileContainer}>
                {!!user && router.route === '/dashboard' && (
                  <UpgradeButton className={classes.upgradeButton} category="video" />
                )}
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, open && classes.hide)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  className={classes.drawer}
                  anchor="left"
                  open={open}
                  classes={{
                    paper: classes.drawerPaper
                  }}
                >
                  <div>
                    <div className={classes.drawerHeader}>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="100px"
                        height="45px"
                        viewBox="0 0 185.3 59.8"
                        style={{ enableBackground: 'new 0 0 185.3 59.8' }}
                      >
                        <g>
                          <path
                            fill="#414042"
                            d="M46.6,47.6H38l-4.8-18.7c-0.2-0.7-0.5-2-0.9-4.1c-0.4-2.1-0.7-3.5-0.7-4.2c-0.1,0.9-0.3,2.3-0.7,4.2
            c-0.4,1.9-0.7,3.3-0.9,4.2L25,47.6h-8.6L7.3,11.9h7.4l4.6,19.5c0.8,3.6,1.4,6.7,1.7,9.3c0.1-0.9,0.3-2.4,0.7-4.3
            c0.3-1.9,0.7-3.5,1-4.5l5.2-20h7.1l5.2,20c0.2,0.9,0.5,2.3,0.9,4.1c0.3,1.8,0.6,3.4,0.8,4.7c0.2-1.3,0.4-2.8,0.8-4.7
            c0.4-1.9,0.7-3.4,1-4.6l4.5-19.5h7.4L46.6,47.6z"
                          />
                          <path
                            fill="#414042"
                            d="M138.9,47.3h-8.6L122,33.9l-8.3,13.5h-8.1L117.4,29l-11.1-17.3h8.3l7.7,12.8l7.5-12.8h8.1l-11.2,17.7
            L138.9,47.3z"
                          />
                          <path
                            fill="#414042"
                            d="M177.2,29.5c0,5.9-1.5,10.4-4.4,13.6c-2.9,3.2-7.1,4.8-12.6,4.8c-5.5,0-9.7-1.6-12.6-4.8
            c-2.9-3.2-4.4-7.7-4.4-13.7s1.5-10.5,4.4-13.6c2.9-3.1,7.1-4.7,12.6-4.7s9.7,1.6,12.6,4.7C175.8,19,177.2,23.6,177.2,29.5z
             M151.2,29.5c0,4,0.8,7,2.3,9c1.5,2,3.8,3,6.8,3c6,0,9-4,9-12c0-8-3-12-9-12c-3,0-5.3,1-6.8,3C152,22.5,151.2,25.5,151.2,29.5z"
                          />
                          <g>
                            <polygon
                              fill="#FFD400"
                              points="87.9,33 87.9,25.3 81.3,21.5 74.6,25.3 74.6,33 81.3,36.8 		"
                            />
                            <polygon
                              fill="#FF0086"
                              points="87.9,17.7 87.9,10 81.3,6.2 74.6,10 74.6,17.7 81.3,21.5 		"
                            />
                            <polygon
                              fill="#7C00FF"
                              points="74.6,25.3 74.6,17.7 68,13.8 61.4,17.7 61.4,25.3 68,29.2 		"
                            />
                            <polygon
                              fill="#0086FF"
                              points="74.6,40.7 74.6,33 68,29.2 61.4,33 61.4,40.7 68,44.5 		"
                            />
                            <polygon
                              fill="#40BF00"
                              points="87.9,48.3 87.9,40.7 81.3,36.8 74.6,40.7 74.6,48.3 81.3,52.1 		"
                            />
                            <polygon
                              fill="#FF7F00"
                              points="101.2,40.6 101.2,33 94.5,29.2 87.9,33 87.9,40.6 94.5,44.5 		"
                            />
                            <polygon
                              fill="#FF0000"
                              points="101.2,25.3 101.2,17.7 94.5,13.9 87.9,17.7 87.9,25.3 94.5,29.2 		"
                            />
                          </g>
                        </g>
                      </svg>
                      <IconButton onClick={handleDrawerClose} className={classes.backIcon}>
                        <ClearIcon />
                      </IconButton>
                    </div>
                    <div className={classes.menuMobileWrapper}>
                      <ExpandedMenu type={'videoAI'} />
                      <ExpandedMenu type={'widget'} />
                      {/* <ExpandedMenu type={'video'} /> */}
                      <Link
                        href="/plans"
                        activeClassName={classes.activeLink}
                        className={classes.menuLinkMobile}
                      >
                        {translate('plans', lang, 'Plans')}
                      </Link>
                      <Link
                        href="/about"
                        activeClassName={classes.activeLink}
                        className={classes.menuLinkMobile}
                      >
                        {translate('aboutUs', lang, 'About us')}
                      </Link>
                      {/* <TutorialVideos isMobileView /> */}
                      {/* <a
                        href="https://woxo.tech/blog/"
                        className={classes.menuLinkMobile}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translate('blog', lang, 'Blog')}
                      </a> */}
                      {/* <Link
                        href="https://support.woxo.tech/en/"
                        className={classes.menuLinkMobile}
                        target="_blank"
                      >
                        {translate('supportCenter', lang, 'Support center')}
                      </Link> */}

                      {!user && showLogin && (
                        <div className={classes.sectionMobile} style={{ marginBottom: 32 }}>
                          <LoginButton onClick={handleLogin} label="Log in" />
                        </div>
                      )}
                      {!user && showSignUp && (
                        <div className={classes.sectionMobile} style={{ marginBottom: 32 }}>
                          <SignupButton onClick={handleSignUp} label={signUpLabel} />
                        </div>
                      )}
                      {!!user && showUserMenu && (
                        <div className={classes.sectionMobile} style={{ marginBottom: 32 }}>
                          <UserMenu user={user} />
                        </div>
                      )}

                      {/* <Link
                        id="help-btn"
                        href="https://support.woxo.tech/en/"
                        className={clsx(classes.menuLinkMobile, 'help-btn')}
                        target="_blank"
                      >
                        <HelpOutlineIcon />
                      </Link> */}
                      <HelpMenu />

                      {/* {showLanguageMenu && (
                        <div>
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            style={{ marginTop: 32 }}
                            className={classes.languageSelector}
                          >
                            <LanguageButton language={lang} />
                          </Button>
                          <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                          >
                            <MenuItem
                              data-name="navbar-lang-en"
                              data-type="btn-click"
                              onClick={handleClose}
                            >
                              <Link href="/" className={classes.languageTextLink}>
                                <USFlag />
                                <Typography className={classes.languageText}>ENG</Typography>
                              </Link>
                            </MenuItem>
                            <MenuItem
                              data-name="navbar-lang-es"
                              data-type="btn-click"
                              onClick={handleClose}
                            >
                              <Link href="/es" className={classes.languageTextLink}>
                                <SpainFlag />
                                <Typography className={classes.languageText}>ES</Typography>
                              </Link>
                            </MenuItem>
                            <MenuItem
                              data-name="navbar-lang-pt"
                              data-type="btn-click"
                              onClick={handleClose}
                            >
                              <Link href="/pt" className={classes.languageTextLink}>
                                <BrazilFlag />
                                <Typography className={classes.languageText}>PT-Br</Typography>
                              </Link>
                            </MenuItem>
                          </Menu>
                        </div>
                      )} */}
                    </div>
                  </div>
                </Drawer>
              </Box>
            </div>
            {endContent}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

MainAppBar.propTypes = {
  position: PropTypes.string,
  className: PropTypes.string,
  lang: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  endContent: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  showSignUp: PropTypes.bool,
  showLogin: PropTypes.bool,
  loginRedirect: PropTypes.bool,
  showLanguageMenu: PropTypes.bool,
  showUserMenu: PropTypes.bool,
  signUpLabel: PropTypes.string,
  showNotificationPanel: PropTypes.bool,
  showTutorials: PropTypes.bool,
  showLinks: PropTypes.bool
};

MainAppBar.defaultProps = {
  position: 'fixed',
  lang: '',
  children: null,
  endContent: null,
  className: null,
  showSignUp: true,
  showLogin: true,
  loginRedirect: true,
  showLanguageMenu: true,
  showUserMenu: true,
  signUpLabel: null,
  showNotificationPanel: true,
  showTutorials: true,
  showLinks: true
};

export default MainAppBar;
